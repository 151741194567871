<template>
  <div>
    <div class="newsList_bg">
      <img src="../../static/image/newsList_bg.png" />
    </div>
    <div class="newsList_tab">
      <div class="newsList_tab_name moveTop" v-for="(item, index) in tabList" @click="tabClick(index)" :key="item">
        {{ item }}
        <div :class="tabIndex == index ? 'newsList_tab_name_tabActive' : ''"></div>
      </div>
    </div>
    <div class="newsList_line"></div>
    <div class="newsList_list">
      <div class="newsList_list_recommend">
        <div class="newsList_list_recommend_title">
          文章推荐
        </div>
        <div class="newsList_list_recommend_line"></div>
        <ul class="newsList_list_recommend_ul">
          <li class="moveTop" v-for="item in recommendList" :key="item.newsId" @click="toDetails(item)">
            {{ item.newsTitle }}
          </li>
        </ul>
      </div>
      <div class="newsList_list_table">
        <div class="newsList_list_table_title">{{ tabList[tabIndex] }}</div>
        <div class="newsList_list_table_line"></div>
        <ul class="newsList_list_table_ul">
          <li class="moveTop" v-for="item in newsList" :key="item.newsId" @click="toDetails(item)">
            {{ item.newsTitle }}
          </li>
        </ul>
      </div>
    </div>
    <div class="newsList_pagination">
      <el-pagination layout="prev, pager, next" :page-size="5" :total="query.total"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "newsList",
  data() {
    return {
      tabList: ["信息披露", "新闻资讯", "通知公告", "风险提示", "购销指南"],
      tabId: ["8", "9", "20", "7", "6"],
      tabIndex: 0,
      recommendList: [],
      newsList: [],
      query: {
        page: 1,
        size: 5,
        total: 0,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
    if (this.$route.query.index) {
      this.tabIndex = this.$route.query.index;
    }
    this.getList(this.tabId[this.tabIndex]); // this.tabId[this.tabIndex] 信息纰漏
    this.getList(3); // 18 文章推荐
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      const that = this
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title:  '中峪数交新闻资讯', // 中峪数交新闻资讯
            desc: that.tabList[that.tabIndex], // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/523ff1ea32f44d9c8bdfc9c33425dd54.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    toDetails(item) {
      this.$router.push({
        path: "/newsListDetail",
        query: { id: item.newsId, type: this.tabList[this.tabIndex] },
      });
    },
    tabClick(index) {
      this.tabIndex = index;
      this.getList(this.tabId[index]); // this.tabId[index]
    },
    getList(categoryId) {
      const page = categoryId == 3 ? 1 : this.query.page;
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=${page}&size=5&websiteCategoryId=${categoryId}`,
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.newsTitle =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          if (categoryId == 3) {
            this.recommendList = content;
          } else {
            this.newsList = content;
            this.query.total = res.data.data.total
          }
        });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList(this.tabId[this.tabIndex]);
    },
  },
  watch: {
    $route: function (val) {
      if (val.query.index || val.query.index == 0) {
        this.tabIndex = val.query.index
      }
      this.getList(this.tabId[this.tabIndex]); // this.tabId[this.tabIndex] 信息纰漏
      this.getWXInfo()
    },
  },
};
</script>
<style scoped lang="scss">
.newsList_bg {
  min-width: 1240px;

  img {
    width: 100%;
  }
}

.newsList_tab {
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  width: 1200px;
  padding: 0 20px;

  .newsList_tab_name {
    font-size: 16px;
    font-weight: 400;
    color: #565759;
    line-height: 22px;
    position: relative;
    cursor: pointer;

    .newsList_tab_name_tabActive {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #9e1307;
    }
  }
}

.newsList_line {
  width: 100%;
  height: 1px;
  background: #f7f7f7;
}

.newsList_list {
  margin: 40px auto;
  display: flex;
  width: 1240px;

  .newsList_list_recommend {
    width: 316px;
    background: #f7f7f7;
    padding: 21px 18px 10px 18px;

    .newsList_list_recommend_title {
      font-size: 16px;
      font-weight: bold;
      color: #4d5461;
      line-height: 22px;
      margin-bottom: 16px;
    }

    .newsList_list_recommend_line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      margin-bottom: 10px;
    }

    .newsList_list_recommend_ul {
      padding: 0;

      li {
        list-style-type: none;
        line-height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #4d5461;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
      }

      li:before {
        content: "";
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        background: #565759;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }

  .newsList_list_table {
    width: calc(100% - 316px - 36px);
    margin-left: 24px;

    .newsList_list_table_title {
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 28px;
    }

    .newsList_list_table_line {
      margin-top: 16px;
      width: 100%;
      height: 1px;
      background: #f7f7f7;
    }

    .newsList_list_table_ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        line-height: 62px;
        font-size: 14px;
        font-weight: 400;
        color: #1d1b19;
        border-bottom: 1px dashed #e5e5e5;
        cursor: pointer;
      }

      li:before {
        content: "";
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        background: #565759;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
}

.newsList_pagination {
  width: 1240px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 100px;
  margin-top: 50px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #9e1307;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active+li {
  border: 1px solid #e5e5e5;
}
</style>
